<template>
	<section class="support-overview center">
		<div style="width:100%; max-width:1200px;">
			<quote-title label="捐款支持金枝" />
			<br>
			<br>
			<div class="center">
				<el-radio-group v-model="query.archived" @change="handleSearch()">
					<el-radio-button :label="false">已捐款</el-radio-button>
					<el-radio-button :label="true">封存</el-radio-button>
				</el-radio-group>
			</div>
			<br>
			<el-card :body-style="{ padding:0, overflow:'auto' }">
				<table>
					<tr>
						<th class="theme_text">姓名</th>
						<th class="theme_text">信箱</th>
						<th class="theme_text">金額</th>
						<th class="theme_text">方式</th>
						<th />
					</tr>
					<tr v-for="(item, i) in _supportList" :key="i">
						<td>{{item.name}}</td>
						<td>{{item.email}}</td>
						<td>{{item.how_much | currencyFilter}}</td>
						<td>{{item.payment}}</td>
						<td>
							<router-link :to="`/manager/support/${item.id}`">
								<el-button icon="el-icon-user" size="mini" type="text">詳細</el-button>
							</router-link>
						</td>
					</tr>
				</table>
			</el-card>
			<br>
			<el-pagination :total="_total" :page-size="Number(query.size)" :current-page="Number(query.page)" @current-change="handleChangePage" layout="prev, pager, next" background />
		</div>
	</section>
</template>

<script>
import { mapActions, mapState } from "vuex"
export default {
	components: {
		Popconfirm: () => import("@/components/popconfirm"),
		clipboard: () => import("@/components/clipboard"),
	},
	data() {
		return {
			query: new Object,
		}
	},
	computed: {
		...mapState("support", [
			"_total",
			"_supportList"
		])
	},
	methods: {
		...mapActions("support", [
			"_getList",
		]),
		async handleSearch() {
			const query = {
				...this.$route.query,
				...this.query
			};
			this.$router.push({
				path: this.$route.path,
				query
			});
			await this._getList(query);
		},
		handleChangePage(page) {
			this.$set(this.query, "page", page);
			this.handleSearch();
		},
	},
	created() {
		const {
			page = 1,
			size = 10,
			archived = false,
		} = this.$route.query;
		this.$set(this.$data, "query", { page, size, archived });
		this._getList(this.query);
	}
}
</script>

<style lang="scss" scoped>
.el-card {
	border-radius: 20px;
}
table {
	width: 100%;
	white-space: nowrap;
	text-align:center;
}
tr:nth-child(even) {
	background: $theme-background;
}
th,
td {
	text-align: center;
	padding: 1em 2em;
}
td:first-child {
	max-width: 10em;

	.ellipsis {
		display: block;
		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		color: $theme;

		&:hover {
			text-decoration: underline;
		}
	}
}
</style>